import Vue from 'vue'
import VueRouter from 'vue-router'
import AuthMiddleware from './auth.guard'
import HomeGuard  from './home.guard'
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import AuthLayout from "@/layouts/Auth.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: "/auth",
    component: AuthLayout,
    redirect: "/auth/login",
    children: [
      {
        name: "Login",
        path: "login",
        component: () => import(/* webpackChunkName: "home" */ '../views/auth/Login.vue'),
        meta: { protected: false, name: 'Sign In' }
      },
      {
        name: "Register",
        path: "register",
        component: () => import(/* webpackChunkName: "home" */ '../views/auth/Register.vue'),
        meta: { name: 'Sign Up' }
      },
      {
        name: "VerifyMsg",
        path: "verifymsg",
        component: () => import(/* webpackChunkName: "home" */ '../views/auth/VerifyMsg.vue'),
        meta: { name: 'VerifyMsg' }
      },
      {
        name: "ForgetPassword",
        path: "forgetpassword",
        component: () => import(/* webpackChunkName: "home" */ '../views/auth/ForgetPassword.vue'),
        meta: { name: 'ForgetPassword' }
      },
      {
        name: "ForgetConfirmation",
        path: "forgetconfirmation",
        component: () => import(/* webpackChunkName: "home" */ '../views/auth/ForgetConfirmation.vue'),
        meta: { name: 'ForgetConfirmation' }
      },      
    ]
  },
  {
    path: "/home",
    component: DefaultLayout,
    meta: { protected: false },
    children: [
      {
        name: "Home",
        path: "/",
        component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
        meta: { protected: false, name: 'Home' },
        // beforeEnter:HomeGuard,
      },
      {
        name: "BrowseAuctions",
        path: "/browseauctions",
        component: () => import('@/views/BrowseAuctions.vue'),
        meta: { protected: true, name: 'Browse Auctions' },
        children: [
          {
            name: "PlaceBid",
            path: "placebid",
            props: true,
            component: () => import(/* webpackChunkName: "home" */ '../views/PlaceBid.vue'),
            meta: { name: 'PlaceBid' }
          },
          {
            name: "BuyNow",
            path: "buynow",
            component: () => import(/* webpackChunkName: "home" */ '../views/BuyNow.vue'),
            meta: { name: 'BuyNow' }
          }
        ]
      },
      {
        name: "MyAccount",
        path: "/myaccount/:id",
        component: () => import('@/views/MyAccount.vue'),
        meta: { protected: true, name: 'My Account' }
      },
      {
        name: "Advertise",
        path: "/Advertise",
        component: () => import('@/views/Advertise.vue'),
        meta: { protected: true, name: 'Advertise' }
      },      
      {
        name: "CreateAuction",
        path: "/createauction",
        component: () => import('@/views/CreateAuction.vue'),
        meta: { protected: true, name: 'Create Auction' }
      },
      {
        name: "TitleMarket",
        path: "/titlemarket",
        component: () => import('@/views/TitleMarket.vue'),
        meta: { protected: true, name: 'TitleMarket' }
      },
      {
        name: "WhitePaper",
        path: "/whitepaper",
        component: () => import('@/views/WhitePaper.vue'),
        meta: { protected: false, name: 'WhitePaper' }
      },
      {
        name: "Wallets",
        path: "/wallets",
        component: () => import('@/views/Wallets.vue'),
        meta: { protected: true, name: 'Wallets' }
      },
      {
        name: "Games",
        path: "/games",
        component: () => import('@/views/Games.vue'),
        meta: { protected: true, name: 'Games' }
      },
      {
        name: "News",
        path: "/news",
        component: () => import('@/views/News.vue'),
        meta: { protected: false, name: 'News' }
      },
      {
        name: "Team",
        path: "/team",
        component: () => import('@/views/Team.vue'),
        meta: { protected: false, name: 'Team' }
      },
      {
        name: "Terms",
        path: "/terms",
        component: () => import('@/views/Terms.vue'),
        meta: { protected: false, name: 'Terms' }
      },
      {
        name: "Form",
        path: "/form",
        component: () => import('@/views/GoogleForm.vue'),
        meta: { protected: false, name: 'Form' }
      },
      {
        name: "Campaign",
        path: "/campaign",
        component: () => import('@/views/CreateCampaign.vue'),
        meta: { protected: true, name: 'Campaign' }
      },
    ]
  },
]

const router = new VueRouter({
  routes,
  mode: "history"
})

// router.beforeEach(AuthMiddleware)

export default router