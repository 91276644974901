import Vue from 'vue'
import App from './App.vue'
import './assets/tailwind.css'
import { Amplify } from 'aws-amplify';
import '@aws-amplify/ui-vue'
import router from './router'
import store from './store'
import directives from './directives';
import moment from 'moment';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import './styles.css'
import InfiniteLoading from 'vue-infinite-loading';

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

Vue.use(moment);
Vue.use(VueToast, {
  // One of the options
  position: 'top'
});
Vue.use(InfiniteLoading, { /* options */ });


Amplify.configure({
  Auth: {
    region: 'us-west-2',
    userPoolId: 'us-west-2_wItToCbsB',
    userPoolWebClientId: '6l1vfubth98eoo3qbgjf6jhbiu',
    mandatorySignIn: true
  }
})

const myAppConfig = {
  'aws_appsync_graphqlEndpoint' : 'https://l5cpgc5devhaxlpkr2mfanggki.appsync-api.us-west-2.amazonaws.com/graphql',
  'aws_appsync_region' : 'us-west-2',
  'aws_appsync_authenticationType': 'AMAZON_COGNITO_USER_POOLS'
}

Amplify.configure(myAppConfig)

Vue.filter('dateformat', function (value) {
  if (!value) return ''
  value = value.toString()
  return moment(value).format('MM/DD/YYYY hh:mm a')
})

Vue.config.productionTip = false

Vue.use(directives);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
